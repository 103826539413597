import React, { useRef } from 'react';
import Button from './Button';
import { icClose } from '../assets/images/images';
import { useMediaQuery } from 'react-responsive';
import Sheet from 'react-modal-sheet';

export default function ShareModal2Buttons({
  title,
  text,
  firstButtonText,
  firstButtonHandler,
  secondButtonText,
  secondButtonHandler,
  onClose,
}) {
  const ref = useRef();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  return (
    <>
      <div style={{ zIndex: isTabletOrMobile ? 1000 : 1 }} className='mask' />
      {isTabletOrMobile ? (
        <Sheet ref={ref} detent='content-height' onBlur={() => onClose()} isOpen={true} onClose={() => onClose()}>
          <Sheet.Container style={{ direction: 'rtl', borderTopRightRadius: 8, borderTopLeftRadius: 8 }}>
            <Sheet.Content>
              <Sheet.Scroller>
                <div>
                  {onClose && (
                    <img
                      style={{ position: 'absolute', left: 20, top: 20 }}
                      alt=''
                      width='24'
                      height='24'
                      onClick={onClose}
                      src={icClose}
                    />
                  )}
                  <div style={{ margin: '20px', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
                    <span style={{ marginTop: 12, fontSize: 24, fontWeight: 'bold', textAlign: 'right' }}>{title}</span>
                    {text && <span style={{ marginTop: 12, fontSize: 18, textAlign: 'right' }}>{text}</span>}
                    <div
                      style={{
                        alignSelf: 'center',
                        width: '100%',
                        marginTop: 40,
                        position: 'relative',
                        justifyContent: 'center',
                      }}
                      className='d-flex row'
                    >
                      {firstButtonText && <Button onPress={firstButtonHandler} text={firstButtonText} />}
                      {secondButtonText && (
                        <Button
                          style={{
                            backgroundColor: 'white',
                            border: '1px solid #4f35be',
                            color: '#4f35be',
                            fontWeight: 'normal',
                          }}
                          onPress={secondButtonHandler}
                          text={secondButtonText}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
        </Sheet>
      ) : (
        <>
          <div className='dialog'>
            <div style={{ width: '95%' }}>
              {onClose && (
                <img
                  style={{ cursor: 'pointer', position: 'absolute', left: 24 }}
                  alt=''
                  width='24'
                  height='24'
                  onClick={onClose}
                  src={icClose}
                />
              )}
              <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span
                  style={{
                    marginTop: 12,
                    fontSize: isTabletOrMobile ? 24 : 28,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {title}
                </span>
                {text && (
                  <span style={{ marginTop: 12, fontSize: isTabletOrMobile ? 18 : 20, textAlign: 'center' }}>
                    {text}
                  </span>
                )}
                <div
                  style={{ width: '95%', marginTop: 40, position: 'relative', justifyContent: 'center' }}
                  className='d-flex row'
                >
                  {firstButtonText && (
                    <Button style={{ width: 160 }} onPress={firstButtonHandler} text={firstButtonText} />
                  )}
                  {secondButtonText && (
                    <Button
                      style={{
                        width: 160,
                        backgroundColor: 'white',
                        border: '1px solid #4f35be',
                        color: '#4f35be',
                        fontWeight: 'normal',
                      }}
                      onPress={secondButtonHandler}
                      text={secondButtonText}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
