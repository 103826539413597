import React, { useEffect, useRef, useState } from 'react';
import {
  icMenu,
  icLogo,
  icNotification,
  icDefault,
  icClose,
  icProfileIcon,
  icExitIcon,
  icMenuMobile,
  icLogoMobile,
  icNotifWhite,
  icNotificationRed,
  icNotifRed,
} from '../assets/images/images';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileSideMenu from './MobileSideMenu';
import Sheet from 'react-modal-sheet';
import Dialog from './Dialog';
import Notifications from './Notifications';
import { getNotif, readNotif } from '../store/actions';

export default function MobileHeader({ type }) {
  const currentUser = useSelector((state) => state.userData);
  const company = useSelector((state) => state.company);
  const notification = useSelector((state) => state.notification);
  const step = useSelector((state) => state.step);

  const dispatch = useDispatch();
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleNotif, setIsVisibleNotif] = useState(false);
  const [isRead, setIsRead] = useState(null);

  const location = useLocation();
  const userType = location?.pathname.split('/')[1];
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const [userImage, setUserImage] = useState(null);
  useEffect(() => {
    setUserImage(currentUser?.type?.id === 3 ? company?.company?.logo : currentUser?.image);
  }, [company?.company?.logo, currentUser]);

  const handleLogout = () => {
    currentUser?.type?.id === 3 ? navigate('/company-login') : navigate('/employee-login');
    localStorage.clear();
    sessionStorage.clear();
    dispatch({ type: 'signOut' });
  };

  const handleReadAll = () => {
    dispatch(readNotif(currentUser?.token, null)).then(() => {
      setTrigger(!trigger);
    });
  };

  const handleNavigate = (notification) => {
    const body = {
      ids: notification ? [notification.id] : [],
    };
    dispatch(readNotif(currentUser?.token, body)).then(() => {
      setTrigger(!trigger);
    });
    if (notification)
      switch (notification.type.type) {
        case 'new match':
          dispatch({ type: 'setTab', payload: 1 });
          dispatch({ type: 'setTabMatch', payload: 1 });
          isCompany ? navigate('/company/candidates') : navigate('/employee/candidates');
          break;
        case 'employee cancel interview':
        case 'company cancel interview':
        case 'new interview scheduled':
        case 'employee interview starts now':
        case 'company interview starts now':
          dispatch({ type: 'setTab', payload: 2 });
          dispatch({ type: 'setTabMatch', payload: 2 });
          isCompany ? navigate('/company/candidates') : navigate('/employee/candidates');
          break;
        case 'employee request new dates for inteview':
          dispatch({ type: 'setTab', payload: 2 });
          dispatch({ type: 'setTabMatch', payload: 2 });
          isCompany ? navigate('/company/candidates', { state: 2 }) : navigate('/employee/candidates');
        default:
          break;
      }
    setIsVisibleNotif(false);
  };
  const isCompany = currentUser?.type?.id === 3;

  useEffect(() => {
    const tmpIsRead = notification.filter((item) => !item.isRead).length;
    setIsRead(tmpIsRead);
  }, [notification]);

  useEffect(() => {
    currentUser !== null && dispatch(getNotif(isCompany ? company?.company?.id : null, currentUser?.token));
  }, [trigger]);

  return (
    <div
      className={
        location.pathname === '/company' || location.pathname === '/employee' || step == 6 ? '' : 'header-mobile'
      }
    >
      <MobileSideMenu
        type={location.pathname.includes('company') ? 'company' : 'employee'}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
      />

      {location.pathname === '/company' || location.pathname === '/employee' || step === 6 ? (
        <div className='header-dashboard'>
          <img onClick={() => setOpenDrawer(true)} alt='' src={icMenuMobile} />
          <div style={{ width: currentUser !== null ? '50%' : 'auto' }}>
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (currentUser === null) window.location.href = 'https://nona-jobs.com/';
                else navigate(`/${userType.includes('employee') ? 'employee' : 'company'}`);
              }}
              alt=''
              width='91'
              height='20'
              src={icLogoMobile}
            />
          </div>
          {currentUser !== null && (
            <div
              style={{
                backgroundColor: '#ffc001',
                width: '46px',
                textAlign: 'center',
                borderRadius: '40px',
                lineHeight: '22px',
                fontSize: '12px',
                color: 'white',
                height: '22px',
              }}
            >
              בהרצה
            </div>
          )}
          {currentUser !== null ? (
            <div>
              <img
                style={{ height: 20, width: 20, cursor: 'pointer' }}
                onClick={() => setIsVisibleNotif(true)}
                alt=''
                width='91'
                height='20'
                src={isRead > 0 ? icNotifRed : icNotifWhite}
              />
              <img
                onClick={() => {
                  setIsVisible(true);
                }}
                width='34'
                height='34'
                alt=''
                style={{ objectFit: 'cover', borderRadius: 17, marginRight: 12 }}
                src={
                  currentUser?.type?.id === 3
                    ? currentUser?.companyTeams[0]?.company?.logo?.url
                    : currentUser?.image?.url ?? icDefault
                }
              />
            </div>
          ) : (
            <div
              style={{
                backgroundColor: '#ffc001',
                width: '46px',
                textAlign: 'center',
                borderRadius: '40px',
                lineHeight: '22px',
                fontSize: '12px',
                color: 'white',
                height: '22px',
              }}
            >
              בהרצה
            </div>
          )}
        </div>
      ) : (
        <div style={{ width: '100%' }} className='d-flex flex-row align-items-center justify-content-around'>
          {type !== 'login' ? (
            <div style={{ zIndex: 1000, width: '33%', textAlign: 'right' }}>
              <img onClick={() => setOpenDrawer(true)} alt='' src={icMenu} />
            </div>
          ) : (
            <div style={{ width: '33%' }} />
          )}
          <div style={{ display: 'flex', justifyContent: 'center', width: '33%' }}>
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (currentUser === null) {
                  window.location.href = 'https://nona-jobs.com/';
                } else {
                  navigate(`/${userType.includes('employee') ? 'employee' : 'company'}`);
                }
              }}
              alt=''
              width='91'
              height='20'
              src={icLogo}
            />
          </div>
          <div style={{ width: '33%' }}>
            {currentUser !== null && (
              <>
                <img
                  onClick={() => setIsVisibleNotif(true)}
                  width='20'
                  alt=''
                  src={isRead > 0 ? icNotificationRed : icNotification}
                />
                <img
                  onClick={() => {
                    setIsVisible(true);
                  }}
                  width='34'
                  height='34'
                  alt=''
                  style={{ objectFit: 'cover', borderRadius: 17, marginRight: 12 }}
                  src={
                    currentUser?.type?.id === 3
                      ? currentUser?.companyTeams[0].company?.logo?.url
                      : currentUser?.image?.url ?? icDefault
                  }
                />
              </>
            )}
          </div>
        </div>
      )}
      {
        <>
          {isVisible && <div className='mask' />}
          <Sheet
            ref={ref}
            detent='content-height'
            onBlur={() => setIsVisible(false)}
            isOpen={isVisible}
            onClose={() => setIsVisible(false)}
          >
            <Sheet.Container
              style={{ paddingBottom: 20, direction: 'rtl', borderTopRightRadius: 24, borderTopLeftRadius: 24 }}
            >
              <Sheet.Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  onClick={() => setIsVisible(false)}
                  width='24'
                  height='24'
                  style={{ alignSelf: 'flex-end', marginTop: 24, marginLeft: 20 }}
                  src={icClose}
                  alt=''
                />
                <div style={{ display: 'inline-table' }}>
                  <img
                    width='60'
                    height='60'
                    alt=''
                    style={{ objectFit: 'cover', borderRadius: '50%' }}
                    src={userImage?.url ?? icDefault}
                  />
                </div>
                <span
                  style={{ marginTop: 12, fontSize: 20, color: '#1f0039', fontWeight: 'bold' }}
                  className='title-user'
                >{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
                <span style={{ fontSize: 16, color: '#5233d9' }} className='title-user'>
                  {currentUser?.email}
                </span>
                <div style={{ alignItems: 'center', width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ marginTop: 60, height: 1, backgroundColor: '#d4daf8', width: '90%' }} />
                  <div
                    onClick={() => {
                      setIsVisible(false);
                      currentUser?.type?.id === 3 ? navigate('/company/setting') : navigate('/employee/setting');
                    }}
                    style={{
                      alignSelf: 'flex-start',
                      padding: '10px 20px',
                      gap: 12,
                      alignItems: 'center',
                      display: 'flex',
                      color: '#4f35be',
                    }}
                  >
                    <img src={icProfileIcon} alt='' /> לפרופיל שלי
                  </div>
                  <div
                    onClick={() => {
                      setLogoutVisible(true);
                      setIsVisible(false);
                    }}
                    style={{
                      alignSelf: 'flex-start',
                      padding: '10px 20px',
                      gap: 12,
                      alignItems: 'center',
                      display: 'flex',
                      color: '#4f35be',
                    }}
                  >
                    <img src={icExitIcon} alt='' /> התנתק מהחשבון
                  </div>
                </div>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        </>
      }
      {
        <>
          {isVisibleNotif && <div className='mask' />}
          <Sheet
            ref={ref}
            detent='content-height'
            onBlur={() => setIsVisibleNotif(false)}
            isOpen={isVisibleNotif}
            onClose={() => setIsVisibleNotif(false)}
          >
            <Sheet.Container
              style={{ maxHeight: '70vh', direction: 'rtl', borderTopRightRadius: 12, borderTopLeftRadius: 12 }}
            >
              <Sheet.Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                  alt=''
                  onClick={() => setIsVisibleNotif(false)}
                  width='20'
                  height='20'
                  style={{ position: 'absolute', left: 20, top: 20 }}
                  src={icClose}
                />
                <Notifications
                  setIsVisibleNotif={setIsVisibleNotif}
                  handleReadAll={handleReadAll}
                  handleNavigate={handleNavigate}
                  notification={notification}
                />
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        </>
      }
      {logoutVisible && (
        <Dialog
          title={'התנתקות מהחשבון'}
          text={'האם ברצונך להתנתק מהחשבון?'}
          firstButtonText={'כן, התנתק'}
          secondButtonText={'ביטול'}
          onClose={() => setLogoutVisible(false)}
          secondButtonHandler={() => setLogoutVisible(false)}
          firstButtonHandler={() => handleLogout()}
        />
      )}
    </div>
  );
}
