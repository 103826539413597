import React, { useEffect, useState } from 'react';
import { icError, icEye } from '../../../assets/images/images';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, TextInput, LoadingModal } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import { createEmployee, getUserDetails, saveToFavEmployee, updateUserDevice } from '../../../store/actions';
import { getOS, validatePhoneNum } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import { Terms } from '../../../components';
import moment from 'moment';
import { LOGIN_SUCCESS } from '../../../store/actionTypes';
import { checkStrongPassword, isAbove18 } from '../../../utils/validator';
import PwdConstrains from '../../../components/PwdConstrains';
import trim from 'validator/es/lib/trim';
import isNull from 'lodash/isNull';
import isEmail from 'validator/es/lib/isEmail';
import isNumeric from 'validator/es/lib/isNumeric';
import Autocomplete from 'react-autocomplete';


const menuStyle = {
  borderRadius: '3px',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  padding: '2px 0',
  fontSize: '90%',
  position: 'fixed',
  overflow: 'auto',
  maxHeight: '50%',
  zIndex: 1000,
  direction: 'rtl',
};

export default function EmployeeDetails({ handleNext, data }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.listDetails);
  const [isFetching, setIsFetching] = useState(false);

  const [city, setCity] = useState({ id: null, label: '' });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday] = useState('');
  const [userImage] = useState(null);

  const [phone, setPhone] = useState('');
  const [distance] = useState({ id: null, label: '' });
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [validatePassword, setValidatePassword] = useState('');

  const [cityList, setCityList] = useState([]);
  const [, setDistanceList] = useState([]);

  const [validCity, setValidCity] = useState(false);
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [, setValidBirthday] = useState(false);
  const [, setValidBirthdayAge] = useState(false);

  const [validPhone, setValidPhone] = useState('');
  const [validEmail, setValidEmail] = useState(null);
  const [, setValidTerms] = useState(false);
  const [, setValidDistance] = useState(false);

  const [terms, setTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  const [userExists, setUserExists] = useState(false);

  const [validPassword, setValidPassword] = useState('');
  const likes = useSelector((state) => state.likes);
  const maybes = useSelector((state) => state.maybes);
  const [showPass, setShowPass] = useState(false);
  const [showPassValid, setShowPassValid] = useState(false);

  useEffect(() => {
    const tmpDistanceList = [];
    const tmpList = list?.distanceScore.sort((a, b) => a.minDistance - b.minDistance);
    tmpList?.forEach((item) =>
      tmpDistanceList.push({ id: item.id, label: `${item.minDistance} - ${item.maxDistance} ק״מ` })
    );
    setDistanceList(tmpDistanceList);

    const tmpCity = [];
    list?.city.forEach((city) => tmpCity.push({ id: city.id, label: city.itemTextHeb }));
    setCityList(tmpCity);
  }, [list]);

  const handleSubmit = () => {
    const above18 = isAbove18(birthday);
    if (firstName === '') {
      setValidFirstName(true);
    }
    if (lastName === '') {
      setValidLastName(true);
    }
    if (!moment(birthday, 'DD/MM/YYYY', true).isValid()) {
      setValidBirthday(true);
    }
    if (!above18) {
      setValidBirthdayAge(true);
    }

    const validNumber = validatePhoneNum(phone);
    if (phone === '' || validNumber) {
      setValidPhone(true);
    }

    const valid = isEmail(email, {
      allow_utf8_local_part: false,
    });

    setValidEmail(!!!(!valid || email === ''));

    if (city?.id === null) {
      setValidCity(true);
    }
    if (!checkStrongPassword(password) || password === '' || validatePassword === '' || validatePassword !== password) {
      setValidPassword(true);
    }
    if (!terms) {
      setValidTerms(true);
    }
    if (distance?.id === null) {
      setValidDistance(true);
    }
    if (
      terms &&
      city?.id !== null &&
      firstName !== '' &&
      lastName !== '' &&
      phone !== '' &&
      !validNumber &&
      email !== '' &&
      !!valid &&
      password !== '' &&
      validatePassword !== '' &&
      validatePassword === password &&
      checkStrongPassword(password)
    ) {
      setIsFetching(true);
      const formData = new FormData();

      formData.append('city', city.id);
      formData.append('firstName', firstName);
      formData.append('lastName', lastName);
      formData.append('email', email);
      formData.append('phone', phone);
      formData.append('password', password);
      formData.append('isRegistered', true);
      userImage !== null && formData.append('userImage', userImage, userImage.name);
      dispatch(createEmployee(formData))
        .then(async (res) => {
          dispatch(getUserDetails(res.data.result?.savedEmployee?.user?.id, res.data.result?.accessToken)).then(
            async (result) => {
              if (res.status === 200) {
                dispatch({ type: 'GET_JOB_LIST_REQUEST' });

                if (likes.length > 0 || maybes.length > 0) {
                  likes.forEach((like) => {
                    const formData = new FormData();
                    formData.append('like', true);
                    formData.append('jobId', like.id);

                    dispatch(saveToFavEmployee(formData, res.data.result?.accessToken, true));
                  });
                  maybes.forEach((maybe) => {
                    const formData = new FormData();
                    formData.append('like', true);
                    formData.append('jobId', maybe.id);

                    dispatch(saveToFavEmployee(formData, res.data.result?.accessToken, true));
                  });
                }

                setIsFetching(false);
                const user = {
                  ...res.data.result,
                  ...result.data.result,
                  token: res.data.result?.accessToken,
                };
                sessionStorage.setItem('userData', JSON.stringify(user));
                dispatch({ type: LOGIN_SUCCESS, payload: user });
                handleNext();

                const formData1 = new FormData();
                formData1.append('os', getOS());
                formData1.append('ip', null);
                formData1.append('deviceId', '1');
                formData1.append('pushToken', 'sometoken');
                dispatch(updateUserDevice(formData1, res.data.result?.accessToken));
              }
            }
          );
        })
        .catch((err) => {
          setIsFetching(false);

          if (err.response.data.message.includes('User exists')) {
            setUserExists(true);
            setTimeout(() => {
              setUserExists(false);
            }, 3000);
          }
        });
    }
  };

  const shouldItemRender = (item, value) => {
    if (!value) return true;
    return item.label.toLowerCase().includes(value.toLowerCase());
  };

  return (
    <>
      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          textAlign: 'right',
          fontWeight: 'bold',
          marginTop: isTabletOrMobile ? '16px' : '60px',
        }}
      >
        {'יצירת פרופיל'}
      </span>
      <span style={{ fontSize: '18px', marginTop: '24px' }}>פרטים אישיים</span>
      <div style={{ flexWrap: 'wrap', flexDirection: isTabletOrMobile ? 'column' : 'row' }} className='d-flex'>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validFirstName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={firstName}
          onChange={(value) => {
            setFirstName(value);
            setValidFirstName(false);
          }}
          label={'שם פרטי *'}
        />
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${validLastName ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={lastName}
          onChange={(value) => {
            setLastName(value);
            setValidLastName(false);
          }}
          label={'שם משפחה *'}
        />
      </div>
      <span style={{ fontSize: '18px', marginTop: '24px' }}>פרטי התקשרות</span>
      <div
        style={{ marginTop: 8, flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }}
        className='d-flex'
      >
        <div className='d-flex'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 12,
              border: `1px solid ${validPhone ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={phone}
            onChange={(value) => {
              setPhone((prev) => {
                return isNumeric(value) ? trim(value) : value === '' ? '' : prev;
              });
              setValidPhone(false);
            }}
            label={'טלפון * (יש להזין ספרות בלבד)'}
          />
        </div>
        <TextInput
          style={{
            marginRight: 0,
            marginLeft: 16,
            border: `1px solid ${(!isNull(validEmail) && !validEmail) || userExists ? 'red' : '#D4DAF8'}`,
          }}
          className='auto-complete'
          value={email}
          onChange={(value) => {
            setEmail(trim(value));
            setValidEmail(null);
          }}
          label={'אימייל *'}
        />
      </div>
      {userExists && (
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            height: '41px',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>האימייל קיים במערכת, נא צור קשר לתמיכה</span>
        </div>
      )}
      <span style={{ fontSize: '18px', marginTop: '24px' }}>מגורים</span>
      <div
        style={{
          marginTop: 8,
          flexDirection: isTabletOrMobile ? 'column' : 'row',
          flexWrap: 'wrap',
          maxHeight: '58px',
        }}
        className='d-flex'
      >
        <Autocomplete
          inputProps={{
            style: {
              marginTop: 16,
              border: `1px solid ${validCity ? 'red' : '#D4DAF8'}`,
            },
            className: 'auto-complete icon',
            placeholder: 'עיר *',
          }}
          wrapperStyle={{ width: '100%' }}
          menuStyle={menuStyle}
          value={city.label}
          items={cityList}
          getItemValue={(item) => item.label}
          shouldItemRender={shouldItemRender}
          renderItem={(item, isHighlighted) => (
            <div
              key={item.id}
              style={{
                padding: '8px 14px',
                cursor: 'pointer',
                background: isHighlighted ? '#f0f0f0' : 'transparent',
                color: '#2f2072',
              }}
            >
              {item.label}
            </div>
          )}
          renderInput={(props) => (
            <div style={{ position: 'relative' }}>
              <input {...props} />
            </div>
          )}
          onChange={(e) => {
            const value = e.target.value;
            setCity({ id: null, label: value });
            setValidCity(false);
          }}
          onSelect={(value, item) => {
            setCity(item);
            setValidCity(false);
          }}
        />
      </div>

      <span style={{ fontSize: '18px', marginTop: '24px' }}>יצירת סיסמה</span>
      <PwdConstrains />
      <div
        style={{ flexDirection: isTabletOrMobile ? 'column' : 'row', flexWrap: 'wrap' }}
        className='d-flex position-relative'
      >
        <div className='position-relative'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 16,
              border: `1px solid ${validPassword ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={password}
            security={!showPass}
            onChange={setPassword}
            label={'סיסמה *'}
          />
          <div
            style={{ cursor: 'pointer', position: 'absolute', left: 25, top: 20 }}
            onClick={() => setShowPass(!showPass)}
          >
            <img src={icEye} alt='iceEye' />
          </div>
        </div>
        <div className='position-relative'>
          <TextInput
            style={{
              marginRight: 0,
              marginLeft: 16,
              border: `1px solid ${validPassword ? 'red' : '#D4DAF8'}`,
            }}
            className='auto-complete'
            value={validatePassword}
            security={!showPassValid}
            onChange={(value) => {
              setValidatePassword(value);
              setValidPassword(false);
            }}
            label={'אישור סיסמה *'}
          />
          <div
            style={{ cursor: 'pointer', position: 'absolute', left: 25, top: 20 }}
            onClick={() => setShowPassValid(!showPassValid)}
          >
            <img src={icEye} alt='iceEye' />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 24 }}>
        <Checkbox
          checked={terms}
          onChange={() => setTerms(!terms)}
          handleClick={() => setOpenTerms(true)}
          className='terms'
          label='קראתי והבנתי את תנאי השימוש באתר ומדיניות הגנת הפרטיות של החברה והם מקובלים עלי.'
        />
      </div>
      {(validFirstName || validLastName || validPhone || (!isNull(validEmail) && !validEmail) || validCity) && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            height: '41px',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>יש לוודא שכל הפרטים הוזנו כשורה</span>
        </div>
      )}
      {validPassword && (
        <div
          style={{
            display: 'flex',
            marginTop: 20,
            fontWeight: '500',
            alignItems: 'center',
            textAlign: 'right',
            color: '#ff2525',
            border: '1px solid',
            borderColor: '#ff2525',
            borderRadius: 4,
            padding: '10px 12px',
          }}
        >
          <img alt='error' src={icError} />
          <span style={{ paddingRight: 10, fontSize: 14 }}>
            הסיסמה חייבת להיות באורך של לפחות 8 תווים, עם לפחות אות אחת גדולה, אות אחת קטנה, וספרה אחת
          </span>
        </div>
      )}

      <div style={{ marginTop: '30px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button
          style={{
            opacity: !terms ? '0.5' : '1',
            pointerEvents: !terms ? 'none' : 'all',
          }}
          onPress={() => {
            handleSubmit();
          }}
          text={'הרשם'}
        />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => navigate('/employee')}
          text={'המשך כאורח'}
        />
      </div>
      {openTerms && (
        <Terms
          title={'תנאי השימוש'}
          firstButtonText={'אני מסכים'}
          onClose={() => setOpenTerms(false)}
          firstButtonHandler={() => {
            setTerms(true);
            setOpenTerms(false);
          }}
          secondButtonText={'ביטול'}
          secondButtonHandler={() => setOpenTerms(false)}
        />
      )}
      <LoadingModal visible={isFetching} />
    </>
  );
}
