import { useMediaQuery } from 'react-responsive';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import {
  icAddVideo,
  icArrowDownWhite,
  icArrowRight,
  icBegin,
  icClose,
  icClothes,
  icCouch,
  icCreateVideo,
  icDeleteWhite,
  icError,
  icFrame,
  icLength,
  icPlay,
  icReplay,
  icSchedule,
  icStop,
  icUmbrella,
  icVolume,
} from '../../../assets/images/images';
import { Button, Dialog, FileUploadButton, LoadingModal } from '../../../components';
import Webcam from 'react-webcam';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import { getFileMuxUrl, updateEmployee } from '../../../store/actions';
import toast from 'react-hot-toast';
import { ToastModal } from '../../../components';

const ysFixWebmDuration = require('fix-webm-duration');

export default function EmployeeVideo({ handleStep, handleBack, data }) {
  const currentUser = useSelector((state) => state.userData);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const width = window.innerWidth;
  const [counter, setCounter] = useState(3);
  const employeeVideos = useSelector((state) => state.employeeVideos);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const [startVideo, setStartVideo] = useState(employeeVideos?.length > 0 ? 2 : 0);
  const webcamRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const mediaRecorderRef = useRef(null);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const [startTime, setStartTime] = useState(0);

  useEffect(() => {
    if (startVideo === 1) {
      if (counter === 0) {
        if (webcamRef.current.stream === undefined) return;
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: 'video/webm',
        });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.start();
        setStartTime(Date.now());
        setCapturing(true);
      }

      if (!counter) return;
      const intervalId = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [counter, handleDataAvailable, startVideo]);

  const requestAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      checkPermissions();
    } catch (error) {
      setIsError(true);
      console.error('Error accessing the camera or microphone:', error);
    }
  };

  const checkPermissions = () => {
    if (navigator.permissions) {
      // Check camera permission
      navigator.permissions.query({ name: 'camera' }).then((result) => {
        console.log(result);
        if (result.state === 'granted') {
          setStartVideo(1);
          handleStep(1);
          dispatch({ type: 'setStep', payload: 6 });
        } else if (result.state === 'prompt') {
          requestAccess();
        }
      });

      // Check microphone permission
      navigator.permissions.query({ name: 'microphone' }).then((result) => {
        if (result.state === 'prompt') {
          requestAccess();
        }
      });
    }
  };

  function checkPermissionsAsync() {
    return new Promise((resolve, reject) => {
      try {
        const result = checkPermissions();
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  const handleOpenCamera = async () => {
    try {
      await checkPermissionsAsync();
    } catch (error) {
      console.error('Permission check failed:', error);
      setIsError(true);
    }
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(180);
  const [videos, setVideos] = useState(employeeVideos !== undefined ? employeeVideos : []);
  const [isVisible, setIsVisible] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [openSheet, setOpenSheet] = useState(false);
  const videoRefs = useRef([]);

  const dispatch = useDispatch();

  const [playing, setPlaying] = useState([false]);
  const [chosenVideo, setChosenVideo] = useState({ url: null, blob: null, uploaded: false, file: null });
  const [isError, setIsError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [, setVideoPlaying] = useState(null);
  const [videoConstraints] = React.useState({
    width: { max: 376 },
    height: { max: 668 },
    aspectRatio: 0.55303,
    facingMode: 'user',
  });

  const handleVideoEnd = (index) => {
    videoRefs.current[index].pause();
    const tmp = [...playing];
    tmp[index] = false;
    setPlaying(tmp);
  };

  const handleDownload = useCallback(() => {
    var duration = Date.now() - startTime;

    if (startVideo !== 0) {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: 'video/webm',
        });
        ysFixWebmDuration(blob, duration, function (fixedBlob) {
          const file = new File([fixedBlob], 'video.webm', { type: 'video/webm' });
          const url = URL.createObjectURL(fixedBlob);
          const data = { url: url, blob: fixedBlob, uploaded: false, file };
          const tmpVideos = [...videos];
          tmpVideos.push(data);
          setVideos(tmpVideos);
          setChosenVideo(data);
          setStartVideo(2);
          dispatch({ type: 'setStep', payload: 6 });
          handleStep(2);
          setCounter(3);
          setSeconds(180);
          webcamRef.current = null;
          mediaRecorderRef.current = null;
          setRecordedChunks([]);
        });
      }
    }
  }, [dispatch, handleStep, recordedChunks, startTime, startVideo, videos]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    handleDownload();
  }, [handleDownload]);

  useEffect(() => {
    if (capturing) {
      if (seconds === 0) {
        handleStopCaptureClick();
        setSeconds(0);
      }

      if (!seconds) return;
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [capturing, handleStopCaptureClick, seconds]);

  const handleDelete = (item, index) => {
    const tmpVideos = [...videos];
    tmpVideos.splice(index, 1);
    setVideos(tmpVideos);
    setChosenVideo({ url: null, blob: null, uploaded: false });
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
      videoRefs.current[index].removeAttribute('src');
      videoRefs.current[index].load();
    }
    if (tmpVideos.length === 0) {
      setStartVideo(0);
      dispatch({ type: 'setStep', payload: 6 });
    }
  };

  const handleVideoUpload = () => {
    const toastId = toast.loading('יש להמתין לסיום העלאת הסרטון ,מעלה סרטון', {
      duration: 60000,
    });
    return dispatch(getFileMuxUrl(chosenVideo, null, currentUser?.savedEmployee?.id))
      .then((res) => {
        console.log('video uploaded', res);
        toast.success('הסרטון הועלה בהצלחה!');
        toast.dismiss(toastId);
      })
      .catch((error) => {
        console.error('error', error);
        toast.error('אירעה שגיאה בהעלאת הסרטון');
        toast.dismiss(toastId);
      });
  };

  const handleVideoFileSelect = (e) => {
    const file = e.target.files[0];
    console.log('file type', file.type);
    const blob = new Blob([file], { type: file.type });
    const url = URL.createObjectURL(blob);
    const data = { url: url, blob: blob, uploaded: true, file: file };
    const tmpVideos = [...videos];
    tmpVideos.push(data);
    setVideos(tmpVideos);
    setChosenVideo(data);
    setStartVideo(2);
    dispatch({ type: 'setStep', payload: 6 });
    handleStep(2);
  };

  useEffect(() => {
    recordedChunks.length > 0 && handleDownload();
  }, [handleDownload, recordedChunks]);

  const handleSave = async () => {
    setIsVisible(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('jobRoleExperiences', JSON.stringify(data?.jobRoleExperiences));
    formData.append('previousCompanies', JSON.stringify(data?.previousCompanies));
    if (data?.resume && data?.resume !== null) {
      const encodedFileName = encodeURIComponent(data?.resume.name);
      formData.append('cv1', data?.resume, encodedFileName);
    }

    if (data?.file_1 && data?.file_1 !== null) {
      const encodedFileName = encodeURIComponent(data?.file_1.name);
      formData.append('cv2', data?.file_1, encodedFileName);
    }

    if (data?.file_2 && data?.file_2 !== null) {
      const encodedFileName = encodeURIComponent(data?.file_2.name);
      formData.append('cv3', data?.file_2, encodedFileName);
    }
    formData.append('educations', JSON.stringify(data?.educations));
    if (data?.characteristics)
      formData.append('characteristics', JSON.stringify(data?.characteristics.map((item) => item.id)));
    if (data?.interests) formData.append('skills', JSON.stringify(data?.interests.map((item) => item.id)));
    if (data?.about) formData.append('about', data?.about);

    chosenVideo.blob && handleVideoUpload();
    dispatch(updateEmployee(formData, currentUser?.id, currentUser?.token)).then(() => {
      setTimeout(() => {
        console.log('success');
        setIsSuccess(true);
        setLoading(false);
      }, 3000);
    });
  };

  const openFullscreen = (elem) => {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <>
      <div
        onClick={() => {
          handleBack({});
        }}
        style={{
          cursor: 'pointer',
          marginTop: '16px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img alt='arrow' src={icArrowRight} />
        <span style={{ color: '#4f35be', fontSize: 28, marginRight: 10, fontWeight: 'bold' }}>חזור</span>
      </div>

      <div style={{ alignSelf: 'center', textAlign: 'right', marginTop: isTabletOrMobile ? '24px' : '15px' }}>
        {startVideo === 1 ? (
          <div style={isTabletOrMobile ? { position: 'absolute', right: 0 } : {}}>
            {isError && (
              <div
                style={{
                  alignSelf: 'center',
                  display: 'flex',
                  fontWeight: '500',
                  alignItems: 'center',
                  textAlign: 'right',
                  height: '41px',
                  color: '#ff2525',
                  border: '1px solid',
                  borderColor: '#ff2525',
                  borderRadius: 4,
                  padding: '10px 12px',
                }}
              >
                <img alt='' src={icError} />
                <span style={{ paddingRight: 10, fontSize: 14 }}>{'שגיאה בגישה למצלמה או למיקרופון'}</span>
              </div>
            )}
            <img
              style={{ cursor: 'pointer', zIndex: 1, position: 'absolute', left: 24, top: isTabletOrMobile ? 60 : 100 }}
              alt=''
              width='24'
              height='24'
              onClick={() => {
                setStartVideo(0);
                dispatch({ type: 'setStep', payload: 0 });
              }}
              src={icClose}
            />
            <div
              style={isTabletOrMobile ? { heigth: '100vh', width: '100vw', position: 'relative' } : {}}
              className={isTabletOrMobile ? '' : 'video'}
            >
              {webcamRef.current && <div className='mask-video' />}
              {isTabletOrMobile ? (
                <Webcam
                  audio={true}
                  muted={true}
                  mirrored={true}
                  style={{ width: '100vw', height: '100vh', objectFit: isIOS ? 'fill' : 'cover' }}
                  videoConstraints={{ facingMode: 'user' }}
                  ref={webcamRef}
                />
              ) : (
                <Webcam
                  style={{ borderRadius: isTabletOrMobile ? 0 : 16 }}
                  videoConstraints={videoConstraints}
                  imageSmoothing={true}
                  height={668}
                  muted={true}
                  width={376}
                  audio={true}
                  mirrored={true}
                  ref={webcamRef}
                />
              )}
              {capturing ? (
                <>
                  <div className='time'>
                    <span style={{ color: '#ffffff' }}>
                      {minutes < 10 ? '0' : ''}
                      {minutes}:{remainingSeconds < 10 ? '0' : ''}
                      {remainingSeconds}
                    </span>
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      zIndex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      bottom: 60,
                      width: '100%',
                    }}
                  >
                    <img alt='icon' src={icStop} onClick={handleStopCaptureClick} style={{ height: 94, width: 94 }} />
                  </div>
                </>
              ) : (
                webcamRef.current &&
                webcamRef?.current && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      zIndex: 1,
                      position: 'absolute',
                      bottom: 60,
                    }}
                  >
                    <span style={{ fontWeight: '900', fontSize: 28, color: '#f8f8ff' }}>מצלמים בעוד...</span>
                    <div
                      style={{
                        marginTop: 10,
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 94,
                        width: 94,
                        backgroundImage: `url(${icBegin})`,
                        backgroundRepeat: 'no-repeat',
                      }}
                    >
                      <span style={{ fontWeight: '900', zIndex: 1, fontSize: '28px' }}>{counter}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        ) : startVideo === 0 ? (
          <div style={{ textAlign: isTabletOrMobile ? 'right' : 'center', marginTop: isTabletOrMobile ? 0 : 180 }}>
            <div style={{ display: 'flex', flexDirection: isTabletOrMobile ? 'column-reverse' : 'column' }}>
              <img
                alt='icon'
                style={{
                  alignSelf: 'center',
                  width: isTabletOrMobile ? 200 : 'auto',
                  height: isTabletOrMobile ? 177 : 'auto',
                }}
                src={icCreateVideo}
              />
              <div>
                <p style={{ fontSize: 22, color: '#010428', fontWeight: '900' }}>צור וידאו</p>
                <p style={{ color: '#827aa5' }}>
                  לחצו על הכפתור על מנת להתחיל את<br></br>הקלטת הוידאו. שימו לב לדגשים שניתנו
                </p>
              </div>
            </div>
            <div
              style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 80 }}
              className='d-flex row justify-content-center'
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: isTabletOrMobile ? 'column' : 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 8,
                  width: '100%',
                  marginBlockEnd: 8,
                }}
              >
                <Button
                  style={{
                    backgroundColor: '#4f35be',
                  }}
                  onPress={handleOpenCamera}
                  text={'התחל בצילום'}
                />
                <FileUploadButton onChange={handleVideoFileSelect} type='file' text={'העלאת סרטון'} />
              </div>

              <Button
                style={{
                  backgroundColor: 'white',
                  border: '1px solid #4f35be',
                  color: '#4f35be',
                  fontWeight: 'normal',
                }}
                onPress={() => {
                  setIsVisible(true);
                }}
                text={'דלג'}
              />
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100vw' }}>
            <div
              style={{
                alignSelf: isTabletOrMobile ? 'center' : 'flex-start',
                width: width - 55,
                flexDirection: 'column',
                display: 'flex',
              }}
            >
              {/* <div
                onClick={() => {
                  handleBack({});
                }}
                style={{ marginTop: isTabletOrMobile ? '16px' : '70px', display: 'flex', alignItems: 'center' }}
              >
                <img alt='icon' src={icArrowRight} />
                <span style={{ color: '#4f35be', fontSize: 28, marginRight: 10, fontWeight: 'bold' }}>חזור</span>
              </div> */}
              {videos.length > 1 && (
                <>
                  <span
                    style={{
                      textAlign: 'right',
                      fontSize: isTabletOrMobile ? '24px' : '32px',
                      fontWeight: 'bold',
                      marginTop: 15,
                    }}
                  >
                    {'מעולה!'}
                  </span>
                  <span style={{ textAlign: 'right', fontSize: 16 }}>
                    {chosenVideo.url === null && 'בחר את הסרטון שאהבת ביותר בין שני הסרטונים שצילמת'}
                    {chosenVideo.url !== null && 'כבר עכשיו אתה שלב אחד לפני מועמדים אחרים.'}
                  </span>
                </>
              )}
            </div>
            <div
              style={{ paddingTop: 30, flexDirection: isTabletOrMobile ? 'column' : 'row' }}
              className='d-flex align-items-center'
            >
              {videos.map((item, index) => (
                <div
                  onClick={() => {
                    console.log('item', item);
                    setChosenVideo(item);
                  }}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: isTabletOrMobile ? 0 : 28,
                    marginTop: isTabletOrMobile ? (index > 0 ? 16 : 0) : 0,
                    position: 'relative',
                    boxShadow: '6.8px 9.5px 43.4px 0 rgba(47, 32, 114, 0.18)',
                    padding: 10,
                    background: '#ffffff',
                    borderRadius: '14.3px',
                  }}
                  key={index}
                >
                  {!playing[index] && (
                    <img
                      alt='icon'
                      onClick={() => {
                        isTabletOrMobile && openFullscreen(videoRefs.current[index]);
                        isTabletOrMobile && videoRefs.current[index].play();

                        setVideoPlaying({ url: item, index: index });
                        const tmp = [...playing];
                        tmp[index] = true;
                        setPlaying(tmp);
                      }}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        zIndex: 1,
                        width: isTabletOrMobile ? 50 : 'auto',
                      }}
                      src={icPlay}
                    />
                  )}
                  {chosenVideo.url === item.url && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        bottom: isTabletOrMobile ? 10 : 40,
                        position: 'absolute',
                        zIndex: 1,
                      }}
                    >
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleDelete(item, index);
                        }}
                        style={{
                          cursor: 'pointer',
                          alignSelf: 'center',
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 40,
                            height: 40,
                            borderRadius: '50%',
                            boxShadow: '0 4px 13px 0 rgba(91, 104, 217, 0.11)',
                            backgroundColor: 'rgba(255, 255, 255, 0.3)',
                          }}
                        >
                          <img alt='icon' src={icDeleteWhite} />
                        </div>
                        <span style={{ color: '#f8f8ff', fontSize: 12 }}>{'מחק'}</span>
                      </div>
                      {!item.uploaded && (
                        <div
                          onClick={() => {
                            handleDelete(item, index);
                            handleOpenCamera();
                          }}
                          style={{
                            cursor: 'pointer',
                            marginRight: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: 40,
                              height: 40,
                              borderRadius: '50%',
                              boxShadow: '0 4px 13px 0 rgba(91, 104, 217, 0.11)',
                              backgroundColor: 'rgba(255, 255, 255, 0.3)',
                            }}
                          >
                            <img alt='icon' src={icReplay} />
                          </div>
                          <span style={{ color: '#f8f8ff', fontSize: 12 }}>{'צלם מחדש'}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {isTabletOrMobile ? (
                    <video
                      autoPlay={isIOS && index === videos.length - 1}
                      muted
                      ref={(el) => (videoRefs.current[index] = el)}
                      onEnded={() => handleVideoEnd(index)}
                      style={{
                        objectFit: 'cover',
                        borderRadius: 14,
                        height: chosenVideo.url === item.url ? '210px' : '190px',
                        width: chosenVideo.url === item.url ? '300px' : '280px',
                      }}
                    >
                      <source src={item.url} type='video/mp4' />
                      <source src={item.url} type='video/webm' />
                      <source src={item.url} type='video/quicktime' />
                      <source src={item.url} type='video/ogg' />
                    </video>
                  ) : (
                    <ReactPlayer
                      playing={playing[index]}
                      style={{
                        borderRadius: '20px',
                        overflow: 'hidden',
                      }}
                      url={item.url}
                      onEnded={() => {
                        const tmp = [...playing];
                        tmp[index] = false;
                        setPlaying(tmp);
                      }}
                      controls={false}
                      height={
                        isTabletOrMobile
                          ? chosenVideo.url === item.url
                            ? '217px'
                            : '190px'
                          : chosenVideo.url === item.url
                          ? '660px'
                          : '626px'
                      }
                      width={
                        isTabletOrMobile
                          ? chosenVideo.url === item.url
                            ? '300px'
                            : '280px'
                          : chosenVideo.url === item.url
                          ? '365px'
                          : '348px'
                      }
                    />
                  )}
                </div>
              ))}
              {videos.length === 1 && (
                <>
                  {videos.some((video) => video.uploaded) ? (
                    <div
                      onClick={() => {
                        setStartVideo(1);
                        handleStep(1);
                        dispatch({ type: 'setStep', payload: 6 });
                      }}
                      className='video-card'
                    >
                      <img alt='icon' src={icAddVideo} />
                      <span style={{ color: '#2f2072', fontWeight: '900', fontSize: 16 }}>צלם סרטון</span>
                    </div>
                  ) : (
                    <div className='video-card'>
                      <input
                        accept='video/*' // Accepts any type of video file
                        style={{ cursor: 'pointer', opacity: 0, position: 'absolute', left: 0 }}
                        onChange={(e) => {
                          const file = e.target.files[0];
                          const blob = new Blob([file], { type: file.type });
                          const url = URL.createObjectURL(blob);
                          const data = { url: url, blob: blob, uploaded: true };
                          const tmpVideos = [...videos];
                          tmpVideos.push(data);
                          setVideos(tmpVideos);
                          dispatch({ type: 'setStep', payload: 6 });
                        }}
                        type='file'
                      />
                      <img alt='icon' src={icAddVideo} />
                      <span
                        style={{
                          color: '#2f2072',
                          fontWeight: '900',
                          fontSize: 16,
                          cursor: 'pointer',
                          minWidth: 'max-content',
                        }}
                      >
                        העלאת סרטון
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>

            <div style={{ alignSelf: 'center', width: width - 55, flexDirection: 'column', display: 'flex' }}>
              <div
                style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }}
                className='d-flex row'
              >
                <Button
                  onPress={() => {
                    handleSave();
                    const jobId = sessionStorage.getItem('jobId');
                    console.log('jobId', jobId);
                    if (jobId) {
                      sessionStorage.removeItem('jobId');
                      navigate(`/employee/job/${jobId}`);
                    }
                  }}
                  text={videos.length > 1 ? 'בחר ושמור' : 'סיים'}
                />
              </div>
            </div>
          </div>
        )}
        {isVisible && (
          <Dialog
            title={'שים לב!'}
            text={
              'ניתן לצלם את הסרטון מתי שנוח לך, רק שים לב שעד שאין סרטון בפרופיל לא תופיע בחיפוש של המגייסים ואתה עלול לפספס משרות מרתקות'
            }
            firstButtonText={'לצלם עכשיו'}
            secondButtonText={'אצלם אח״כ'}
            onClose={() => setIsVisible(false)}
            firstButtonHandler={() => {
              setIsVisible(false);
              setStartVideo(1);
              handleStep(1);
              dispatch({ type: 'setStep', payload: 6 });
            }}
            secondButtonHandler={() => {
              handleSave();
              const jobId = sessionStorage.getItem('jobId');
              console.log('jobId', jobId);
              if (jobId) {
                sessionStorage.removeItem('jobId');
                navigate(`/employee/job/${jobId}`);
              }
            }}
          />
        )}
        {isSuccess && (
          <Dialog
            title={'ווהווו!'}
            text={'אנחנו מעריכים מאוד את הזמן והמאמץ שהשקעת והולכים למצוא לך עבודה מהסרטים'}
            firstButtonText={'חפש לי משרות'}
            onClose={() => setIsSuccess(false)}
            firstButtonHandler={() => {
              navigate('/employee');
            }}
          />
        )}
        {isTabletOrMobile && startVideo === 0 && (
          <SwipeableBottomSheet
            overlay={false}
            onChange={() => setOpenSheet(!openSheet)}
            topShadow={false}
            overlayStyle={{ border: '1px solid' }}
            overflowHeight={64}
          >
            <div
              style={{
                padding: '18px 27px',
                color: '#ffffff',
                borderTopRightRadius: 24,
                borderTopLeftRadius: 24,
                backgroundColor: '#2f2072',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                  color: '#f8f8ff',
                  fontSize: 20,
                  fontWeight: 'bold',
                }}
              >
                <img
                  alt='icon'
                  style={{ transform: openSheet ? 'rotate(180deg)' : 'rotate(0deg)' }}
                  src={icArrowDownWhite}
                />
                <span>דגשים לצילום</span>
              </div>
              <p style={{ marginTop: 12, fontSize: 16 }}>
                הגיע הזמן לצאת מבין השורות היבשות של קורות החיים ולהכיר את האדם שמאחוריהם - אותך!
              </p>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  דאג <b>שיהיה לך נוח</b> - מצא מקום בו יש לך פרטיות וצלם את הסרטון כשיש לך זמן וכשאתה לא לחוץ
                </span>
                <img alt='icon' src={icCouch} />
              </div>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  צלם <b>במקום שקט</b> ודאג ל<b>תאורה נכונה</b> - חשוב שישמעו אותך ושיראו אותך באופן ברור
                </span>
                <img alt='icon' src={icVolume} />
              </div>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  <b>שים לב לרקע</b> שמאחוריך - רקע מסודר מייצר רושם ראשוני חיובי, כמו כן רקע ללא הסחות דעת שומר את
                  הפוקוס עליך
                </span>
                <img alt='icon' src={icFrame} />
              </div>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  לבש <b>בגדים המייצגים</b> את המשרה אליה אתה רוצה להתקבל
                </span>
                <img alt='icon' src={icClothes} />
              </div>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  אורך הסרטון הוא עד 3 דקות, חשוב להיות <b>ממוקדים</b> בעיקר
                </span>
                <img alt='icon' src={icLength} />
              </div>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  <b>תכנן</b> מה אתה רוצה לומר מראש - מהן החוזקות שלך, מה מייחד אותך ומי אתה בכללי
                </span>
                <img alt='icon' src={icSchedule} />
              </div>
              <div style={{ marginTop: 24, fontSize: 14 }} className='d-flex align-items-center'>
                <span style={{ paddingRight: 12 }}>
                  והכי חשוב, אתה יכול לצלם את הסרטון <b>כמה פעמים שתרצה</b>, ולהחליף אותו בכל זמן בעתיד אז חייך והתנהג
                  בטבעיות
                </span>
                <img alt='icon' src={icUmbrella} />
              </div>
            </div>
          </SwipeableBottomSheet>
        )}
        <LoadingModal visible={loading} />
        <ToastModal />
      </div>
    </>
  );
}
