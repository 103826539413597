import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../components';
import { useMediaQuery } from 'react-responsive';
import EmployeeInfoDialog from '../../../components/EmployeeInfoDialog';
import { MenuItem, Select } from '@mui/material';
import { icArrowDown } from '../../../assets/images/images';

export default function EmployeeJobRoleV2({ handleNext, data, handleBack }) {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1170px)' });
  const listDetails = useSelector((state) => state.listDetails);
  const [occupationFields, setOccupationFields] = useState(
    data?.occupationFields !== undefined ? data.occupationFields : []
  );
  const [preferredPositions, setPreferredPositions] = useState(
    data?.preferredPositions !== undefined ? data.preferredPositions : []
  );
  const [about, setAbout] = useState(data?.about !== undefined ? data.about : '');

  const [occupationFieldsList, setOccupationFieldsList] = useState([]);
  const [preferredPositionsList, setPreferredPositionsList] = useState([]);

  const [occupationFieldsLimited] = useState(true);
  const [preferredPositionsLimited] = useState(true);

  const [validOccupationFields, setValidOccupationFields] = useState(false);
  const [validPreferredPositions, setValidPreferredPositions] = useState(false);

  const [visibleOccupationFields, setVisibleOccupationFields] = useState(false);
  const [visiblePreferredPositions, setVisiblePreferredPositions] = useState(false);

  const [experience, setExperience] = useState('');

  const experienceList = [
    { label: '0-1 שנים', value: '0-1' },
    { label: '1-3 שנים', value: '1-3' },
    { label: '3-5 שנים', value: '3-5' },
    { label: '5+ שנים', value: '5+' },
  ];

  useEffect(() => {
    const tmpOccupationFields = [];
    listDetails?.jobRoleCategory.forEach((item) => tmpOccupationFields.push({ id: item.id, label: item.itemText }));
    setOccupationFieldsList(tmpOccupationFields);

    const tmpPreferredPositions = [];
    listDetails?.jobRole.forEach((item) => tmpPreferredPositions.push({ id: item.id, label: item.itemText }));
    setPreferredPositionsList(tmpPreferredPositions);
  }, [listDetails]);

  useEffect(() => {
    const filteredPreferredPositions = listDetails.jobRole.filter((position) =>
      occupationFields.some((field) => field.id === position.category.id)
    );
    console.log(filteredPreferredPositions);
    setPreferredPositionsList(filteredPreferredPositions.map((item) => ({ id: item.id, label: item.itemText })));
  }, [occupationFields, listDetails.jobRole]);

  const handleItem = (item, arr, setFunc, key) => {
    const tmpArr = [...arr];
    if (tmpArr.includes(item)) {
      const index = tmpArr.indexOf(item);
      tmpArr.splice(index, 1);
    } else {
      tmpArr.push(item);
    }
    if (key === 'preferredPositions') handleNext({ preferredPositions: tmpArr }, false);
    else handleNext({ occupationFields: tmpArr }, false);

    setFunc(tmpArr);
  };

  const handleSubmit = () => {
    if (occupationFields.length === 0) {
      setValidOccupationFields(true);
      setTimeout(() => {
        setValidOccupationFields(false);
      }, 1000);
    }
    if (preferredPositions.length === 0) {
      setValidPreferredPositions(true);
      setTimeout(() => {
        setValidPreferredPositions(false);
      }, 1000);
    } else if (occupationFields.length > 0 && preferredPositions.length > 0) {
      handleNext(
        {
          occupationFields: occupationFields,
          preferredPositions: preferredPositions,
          about: about,
          experience: experience,
        },
        true
      );
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: isTabletOrMobile ? '24px' : '16px',
        }}
      >
        <span style={{ color: '#4f35be', fontSize: 28, marginRight: 10, fontWeight: 'bold' }}></span>
      </div>
      <span
        style={{
          fontSize: isTabletOrMobile ? '24px' : '32px',
          textAlign: 'right',
          fontWeight: 'bold',
        }}
      >
        {'נעים להכיר! איזה עבודה לחפש עבורך?'}
      </span>
      <span style={{ fontSize: '16px', marginTop: '24px', color: '#827aa5' }}>מהם תחומי העיסוק שלך? (0/3 נבחרו)</span>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '5px',
          justifyContent: isTabletOrMobile ? 'space-between' : 'flex-start',
        }}
        className='div-container'
      >
        {occupationFieldsList.map((item, index) => {
          if (occupationFieldsLimited && index < 7) {
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, occupationFields, setOccupationFields, 'occupationFields')}
                style={{
                  width: isTabletOrMobile ? '44%' : 175,
                  cursor: 'pointer',
                  border: `1px solid ${validOccupationFields ? 'red' : '#D4DAF8'}`,
                  color:
                    occupationFields.length > 0 && occupationFields.some(({ id }) => id === item.id)
                      ? '#ffffff'
                      : '#2f2072',
                  backgroundColor:
                    occupationFields.length > 0 && occupationFields.some(({ id }) => id === item.id)
                      ? '#4f35be'
                      : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          } else if (!occupationFieldsLimited)
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, occupationFields, setOccupationFields, 'occupationFields')}
                style={{
                  width: isTabletOrMobile ? '44%' : 175,
                  cursor: 'pointer',
                  border: `1px solid ${validOccupationFields ? 'red' : '#D4DAF8'}`,
                  color:
                    occupationFields.length > 0 && occupationFields.some(({ id }) => id === item.id)
                      ? '#ffffff'
                      : '#2f2072',
                  backgroundColor:
                    occupationFields.length > 0 && occupationFields.some(({ id }) => id === item.id)
                      ? '#4f35be'
                      : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          return null;
        })}
        <span
          onClick={() => setVisibleOccupationFields(true)}
          style={{
            cursor: 'pointer',
            width: isTabletOrMobile ? '44%' : 175,
            marginTop: 10,
            fontSize: '16px',
            textDecoration: 'underline',
            textAlign: 'center',
          }}
        >
          תחומי עיסוק נוספים
        </span>
      </div>

      <span style={{ fontSize: '16px', marginTop: '24px', color: '#827aa5' }}>
        מה המשרות שמעניינות אותך? (0/3 נבחרו)
      </span>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          justifyContent: isTabletOrMobile ? 'space-between' : 'flex-start',
        }}
        className='div-container'
      >
        {preferredPositionsList.map((item, index) => {
          console.log('item', item);
          if (preferredPositionsLimited && index < 7) {
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, preferredPositions, setPreferredPositions, 'preferredPositions')}
                style={{
                  borderRadius: 30,
                  width: isTabletOrMobile ? '44%' : 175,
                  cursor: 'pointer',
                  border: `1px solid ${validPreferredPositions ? 'red' : '#D4DAF8'}`,
                  color:
                    preferredPositions.length > 0 && preferredPositions.some(({ id }) => id === item.id)
                      ? '#ffffff'
                      : '#2f2072',
                  backgroundColor:
                    preferredPositions.length > 0 && preferredPositions.some(({ id }) => id === item.id)
                      ? '#4f35be'
                      : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          } else if (!preferredPositionsLimited)
            return (
              <div
                key={item.id}
                onClick={() => handleItem(item, preferredPositions, setPreferredPositions, 'preferredPositions')}
                style={{
                  width: isTabletOrMobile ? '44%' : 175,
                  cursor: 'pointer',
                  border: `1px solid ${validPreferredPositions ? 'red' : '#D4DAF8'}`,
                  color:
                    preferredPositions.length > 0 && preferredPositions.some(({ id }) => id === item.id)
                      ? '#ffffff'
                      : '#2f2072',
                  backgroundColor:
                    preferredPositions.length > 0 && preferredPositions.some(({ id }) => id === item.id)
                      ? '#4f35be'
                      : '#ffffff',
                }}
                className={`tag${isTabletOrMobile ? '-mobile' : ''}`}
              >
                {item.label}
              </div>
            );
          return null;
        })}
        <span
          onClick={() => setVisiblePreferredPositions(true)}
          style={{
            width: isTabletOrMobile ? '44%' : 175,
            cursor: 'pointer',
            marginTop: 10,
            fontSize: '16px',
            textDecoration: 'underline',
            textAlign: 'center',
          }}
        >
          משרות נוספות
        </span>
      </div>
      <div
        style={{
          position: 'relative',
          justifyContent: 'flex-start',
          width: '100%',
          marginTop: '24px',
          flexWrap: 'wrap',
          flexDirection: 'row',
          alignItems: 'center',
        }}
        className='d-flex'
      >
        <span style={{ textAlign: 'right', marginLeft: 16 }}>כמה שנות ניסיון יש לך בתפקיד הזה?</span>
        <Select
          inputProps={{
            IconComponent: () => null,
            sx: { padding: '0 !important' },
          }}
          renderValue={(value) => {
            if (value === '') {
              return (
                <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ textAlign: 'right', color: '#827aa5' }}>שנות ניסיון</span>
                  <img alt='arrow' src={icArrowDown} />
                </div>
              );
            }

            return (
              <div style={{ padding: '0px 10px 0px 10px', display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ textAlign: 'right' }}>{value.label}</span>
                <img alt='arrow' src={icArrowDown} />
              </div>
            );
          }}
          displayEmpty
          value={experience}
          variant='outlined'
          onChange={({ target }) => {
            setExperience(target.value);
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            PaperProps: {
              sx: {
                '& .Mui-selected': {
                  backgroundColor: '#f8f8ff',
                },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: '#f8f8ff',
                },
              },
            },
          }}
          sx={{
            fontSize: 16,
            fontFamily: 'Heebo',
            color: '#1f0039',
            height: '48px',
            width: '140px',
            borderRadius: '8px',
            border: `1px solid #D4DAF8`,
            '.MuiOutlinedInput-notchedOutline': {
              border: '0px',
              textAlign: 'right',
            },
          }}
        >
          {experienceList.map((item, index) => {
            return (
              <MenuItem
                key={index}
                sx={{
                  fontSize: 16,
                  fontFamily: 'Heebo',
                  color: '#1f0039',
                  direction: 'rtl',
                }}
                value={item}
              >
                <span>{item.label}</span>
              </MenuItem>
            );
          })}
        </Select>
      </div>
      <div style={{ marginTop: '40px', position: 'relative', marginRight: 0, marginBottom: 20 }} className='d-flex row'>
        <Button onPress={() => handleSubmit()} text={'הבא'} />
        <Button
          style={{ backgroundColor: 'white', border: '1px solid #4f35be', color: '#4f35be', fontWeight: 'normal' }}
          onPress={() => handleNext({}, true)}
          text={'דלג'}
        />
      </div>
      {visibleOccupationFields && (
        <EmployeeInfoDialog
          title={'הוספת תחומי עיסוק'}
          subtitle={'ניתן לבחור עד 3 תחומי עיסוק'}
          data={occupationFields}
          search={'בחר תחומי עיסוק'}
          list={occupationFieldsList}
          firstButtonText={'הוסף'}
          firstButtonHandler={(items) => {
            setVisibleOccupationFields(false);
            setOccupationFields(items);
          }}
          secondButtonHandler={() => {
            setVisibleOccupationFields(false);
          }}
          secondButtonText={'ביטול'}
          onClose={() => setVisibleOccupationFields(false)}
        />
      )}
      {visiblePreferredPositions && (
        <EmployeeInfoDialog
          title={'הוספת משרות מועדפות'}
          subtitle={'ניתן לבחור עד 3 משרות מועדפות'}
          search={'חפש משרות מועדפות'}
          data={preferredPositions}
          list={preferredPositionsList}
          firstButtonText={'שמור'}
          firstButtonHandler={(items) => {
            setVisiblePreferredPositions(false);
            setPreferredPositions(items);
          }}
          secondButtonHandler={() => {
            setVisiblePreferredPositions(false);
          }}
          secondButtonText={'ביטול'}
          onClose={() => setVisiblePreferredPositions(false)}
        />
      )}
    </>
  );
}
